import React from 'react';
import Auth from './Auth.js';


class RegisterNewUser extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email: '',
        password: '',
        password2: '',
        name: '',
        passwordsMatch: false
      };
      this.handleEmailChange = this.handleEmailChange.bind(this);
      this.handleNameChange = this.handleNameChange.bind(this);
      this.handlePasswordChange = this.handlePasswordChange.bind(this);
      this.handlePassword2Change = this.handlePassword2Change.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
       
      event.preventDefault();
      try{
          var auth = new Auth();
          auth.registerUser(this.state.email, this.state.password, this.state.password2, this.state.name,
            (result) => { alert('User Registered!  Now check your email for a verifcation code.');
                          this.props.success();},
            (error) => { alert('Error Registering User: ' + JSON.stringify(error));
                          this.props.cancel();} );
          
      }
      catch (err)
      {
        alert (err);
      }
      }

    passwordsMatch(password1, password2)
    {
      return password1 === password2
    }

    handleEmailChange(event) {
        this.setState({email: event.target.value});
    }

    handleNameChange(event) {
      this.setState({name: event.target.value});
  }

    handlePasswordChange(event) {
        this.setState({password: event.target.value, passwordsMatch: (this.state.password2 === event.target.value)});
    }

    handlePassword2Change(event) {
      this.setState({password2: event.target.value, passwordsMatch: (this.state.password === event.target.value)});
    }

    validToSubmit(email, password, password2, name)
    {
      return (email?.length > 3) && (name?.length> 3) && this.passwordsMatch(password, password2); 
    }

    cancel(){
      this.props.cancel();
    }
    

    render() {
        return (
            <div className="register-new-user">
                <form>
                    <div>
                        <label>Name: </label>
                        <input type="text" value={this.state.name} onChange={this.handleNameChange} />
                    </div><div>
                        <label>Email: </label>
                        <input type="email" value={this.state.email} onChange={this.handleEmailChange} />
                    </div>
                    <div>
                        <label>Password:</label>
                        <input type="password" value={this.state.password} onChange={this.handlePasswordChange}/>
                    </div>
                    <div>
                        <label>Confirm Password:</label>
                        <input type="password" value={this.state.password2} onChange={this.handlePassword2Change}/>
                    </div>
                    <div>
                        <input type='submit' value="Register" onClick={this.handleSubmit} disabled={!this.validToSubmit(this.state.email, this.state.password, this.state.password2, this.state.name)}/>
                    </div>
                    <div>
                        <input type='submit' value='Cancel'onClick={this.cancel} />
                    </div>
                </form>
            </div>
        );
      }
}


export default RegisterNewUser;