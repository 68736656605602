import React from 'react';
import dayjs from 'dayjs';

class HistoryDatePicker extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      };
      this.handleDateChange = this.handleDateChange.bind(this);
      this.changeToToday = this.changeToToday.bind(this);
    }

    handleDateChange(event)
    {
        var newDate = dayjs(event.target.value);
        this.props.handleDateChange(newDate);
        console.log('DateSelected - ' + newDate?.format());
    }

    changeToToday()
    {
        var today = dayjs();
        this.props.handleDateChange(today);
    }

    render() {
        return (
            <form className="date-picker" onSubmit={this.changeToToday}>
                <input type="date" value={this.props.initialDate.format('YYYY-MM-DD')} onChange={this.handleDateChange} name="selectedDate"/>
            </form>
        );
        //<input type="submit" value="Today"/>
      }
}



export default HistoryDatePicker;