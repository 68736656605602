import React from 'react';
import Auth from './Auth.js';


class VerifyCode extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email: '',
        code: ''};
      this.handleEmailChange = this.handleEmailChange.bind(this);
      this.handleCodeChange = this.handleCodeChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.cancel = this.cancel.bind(this);

    }

    handleSubmit(event) {

        event.preventDefault();
        try{
            var auth = new Auth();
            auth.verifyCode(this.state.email, this.state.code, 
                (result) => { alert ('Account Verified! You can now log in.');}, 
                (error) => { alert("Verify Code failed with error: " + JSON.stringify(error)) });
            this.props.cancel();
        }
        catch (err)
        {
          alert (err);
        }
      }

    handleEmailChange(event) {
        this.setState({email: event.target.value});
    }

    handleCodeChange(event) {
        this.setState({code: event.target.value});
    }

    cancel(){
      this.props.cancel();
    }
    

    render() {
        return (
            <div className='verify-code'>
                <form>
                    <div>
                        <label>Email: </label>
                        <input type="email" value={this.state.email} onChange={this.handleEmailChange} />
                    </div>
                    <div>
                        <label>Code:</label>
                        <input type="text" value={this.state.code} onChange={this.handleCodeChange}/>
                    </div>
                    <div>
                        <input type='submit' value="Verify Code" onClick={this.handleSubmit}/>
                    </div>
                    <div>
                        <input type='submit' value='Cancel' onClick={this.cancel} />
                    </div>
                </form>
            </div>
        );
      }
}


export default VerifyCode;