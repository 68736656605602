import _config from './js/config.json';
import AWS from 'aws-sdk';
import {
	CognitoUserPool,
	CognitoUserAttribute,
	CognitoUser,
    AuthenticationDetails
} from 'amazon-cognito-identity-js';


class Auth
{
    getUserPool()
    {
        var poolData = {    
            UserPoolId: _config.cognito.userPoolId,
            ClientId: _config.cognito.userPoolClientId
        };

        if (!(_config.cognito.userPoolId &&
            _config.cognito.userPoolClientId &&
            _config.cognito.region)) {
            alert('No Cognito');
            return;
        }

        if (typeof AWS.AWSCognito !== 'undefined') {
            AWS.AWSCognito.config.region = _config.cognito.region;
        }

        var userPool;
        try{
            userPool = new CognitoUserPool(poolData);

        }
        catch(e)
        {
            alert('User Pool Creation Error = ' + e);
        }

        return userPool;
    }

    signin(email, password, onSuccess, onFailure) {
        var userPool = this.getUserPool();

        var authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: password
        });

        var cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool
        });

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => { 
                onSuccess(result);
            },
            onFailure: (error) => {
                alert('Error signing in.  Erorr:' + error);
                onFailure(error);
            }
        });
    }

    signOut()
    {
        var userPool = this.getUserPool();
        userPool?.getCurrentUser()?.signOut();
    }

    getToken()
    {
        var userPool = this.getUserPool();
        var authToken = new Promise(function fetchCurrentAuthToken(resolve, reject) {
            var cognitoUser = userPool.getCurrentUser();
            if (cognitoUser) {
                cognitoUser.getSession(function sessionCallback(err, session) {
                    if (err) {
                        console.error('Error getting session');
                        reject(err);
                    } else if (!session.isValid()) {
                        console.log('Session not valid');
                        resolve(null);
                    } else {
                        resolve(session.getIdToken().getJwtToken());
                    }
                });
            } else {
                console.error('Unable to get Cognito User from session.')
                resolve(null);
            };
    
        });

        return authToken;
    }

    getUserName()
    {
        var userPool = this.getUserPool();
        var authToken = new Promise(function fetchCurrentAuthToken(resolve, reject) {
            var cognitoUser = userPool.getCurrentUser();
            if (cognitoUser) {
                cognitoUser.getSession(function sessionCallback(err, session) {
                    if (err) {
                        console.error('Error getting session');
                        reject(err);
                    } else if (!session.isValid()) {
                        console.log('Session not valid');
                        resolve(null);
                    } else {
                        resolve(session.getIdToken().payload.name);
                    }
                });
            } else {
                console.error('Unable to get Cognito User from session.')
                resolve(null);
            };
    
        });

        return authToken;

    }

    registerUser(email, password, password2, name, onSuccess, onFailure)
    {
        var attributeList = [];
        var attributeName = new CognitoUserAttribute({Name: 'name', Value: name});
        var attributeEmail = new CognitoUserAttribute({Name: 'email', Value: email});
        attributeList.push(attributeName);
        attributeList.push(attributeEmail);
        
        var userPool = this.getUserPool();
        userPool.signUp(email, password, attributeList, null,
            function signUpCallback(err, result) {
                if (!err) {
                    onSuccess(result);
                } else {
                    onFailure(err);
                }
            }
        );
    }

    verifyCode(email, code, onSuccess, onFailure)
    {
        var userPool = this.getUserPool();

        var cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool
        });

        cognitoUser.confirmRegistration(code, true, function confirmCallback(err, result) {
            if (!err) {
                onSuccess(result);
            } else {
                onFailure(err);
            }
        });
    }

   
}

export default Auth;