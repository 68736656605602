import React from 'react';

class SignOnPanel extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email: '',
        password: ''};
      this.handleEmailChange = this.handleEmailChange.bind(this);
      this.handlePasswordChange = this.handlePasswordChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.registerNewUser = this.registerNewUser.bind(this);
      this.verifyCode = this.verifyCode.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        try{
          this.props.onSignOnSubmit(this.state.email, this.state.password, event);
        }
        catch (err)
        {
          alert (err);
        }
      }

    handleEmailChange(event) {
        this.setState({email: event.target.value});
    }

    handlePasswordChange(event) {
        this.setState({password: event.target.value});
    }

    registerNewUser(){
      this.props.registerNewUser();
    }

    verifyCode(){
      this.props.verifyCode();
    }
    

    render() {
        return (
            <div className='signOnForm'>
                <form>
                    <div>
                        <label htmlFor='email'>Email: </label>
                        <input type="email" id='email' value={this.state.email} onChange={this.handleEmailChange} />
                    </div>
                    <div>
                        <label htmlFor='password'>Password:</label>
                        <input type="password" id='password' value={this.state.password} onChange={this.handlePasswordChange}/>
                    </div>
                    <div>
                        <button onClick={this.handleSubmit}>Submit</button>
                    </div>
                    <div>
                        <input type='submit' onClick={this.registerNewUser} value='Register New User'/>
                        <input type='submit' onClick={this.verifyCode} value='Verify Code'/>
                    </div>
                </form>
            </div>
        );
      }
}


export default SignOnPanel;