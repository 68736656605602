import React from 'react';
import dayjs from 'dayjs';
import HistoryCalendar from './HistoryCalendar.js'
import HistoryDatePicker from './HistoryDatePicker.js'

class InjectionHistory extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        date: dayjs(),
      }
      this.handleDateChange = this.handleDateChange.bind(this);
      this.submitDate = this.submitDate.bind(this);
    }

    handleDateChange(newDate)
    {
        this.setState({ date: newDate});
        console.log('InjectionHistory - handleDateChange - ' + newDate.format());
    }

    submitDate(event)
    {
        console.log(event);
    }

    render() {
        return (
            <div>
                <HistoryDatePicker handleDateChange={this.handleDateChange} submitDate={this.submitDate} initialDate={this.state.date}/>
                <HistoryCalendar selectedDate={this.state.date} signOut={this.props.onSignOut} handleDateChange={this.handleDateChange}/>
            </div>
        );
      }
}

export default InjectionHistory;