import React from 'react';
import InjectionHistory from './InjectionHistory';
import SignOnPanel from './SignOnComponent.js';
import RegisterNewUser from './RegisterNewUser.js';
import VerifyCode from './VerifyCode.js';
import Auth from './Auth.js';


class InjectionsApp extends React.Component {
    constructor(props) {  
      super(props);
      this.state = {
        isLoggedIn: false,
        currentView: 'SignOn',
        userName: null
        };
        this.handleSignOnSubmit = this.handleSignOnSubmit.bind(this);
        this.handleSignOut = this.handleSignOut.bind(this);
        this.switchRegister = this.switchRegister.bind(this);
        this.switchVerify = this.switchVerify.bind(this);
    }

    setUserName()
    {
        var auth = new Auth();
        auth.getUserName().then((name) => {
            this.setState({ userName: name})
        });
    }
    
    componentDidMount() {
        var auth = new Auth();
        auth.getToken().then((token) => {
            this.setState({ isLoggedIn: (token? true : false)})
        });
        this.setUserName();
    }

    switchRegister()
    {
        this.setState({currentView: 'Register'});
    }

    switchVerify()
    {
        this.setState({currentView: 'Verify'});
    }

    handleSignOut()
    {
        new Auth().signOut();
        this.setState({isLoggedIn: false, currentView: 'SignOn', userName: null});
    }

    handleSignOnSubmit(email, password, event)
    {
        var auth = new Auth();
        auth.signin(email, password, () => {
                this.setState({isLoggedIn: true});
                this.setUserName();
            }, () => {
                this.setState({isLoggedIn: false})
            });
    }

    render() {
        let activePanel = <div/>; 
        let signOutPanel = <span/>;
        if (this.state.isLoggedIn)
        {
            activePanel = <InjectionHistory/>;
            signOutPanel = <span><input type='submit' value='Sign Out' onClick={this.handleSignOut}/></span>
        }
        else 
        {
            switch(this.state.currentView)
            {
                case 'SignOn': 
                    activePanel = <SignOnPanel onSignOnSubmit={this.handleSignOnSubmit} registerNewUser={this.switchRegister} verifyCode={this.switchVerify} cancel={this.handleSignOut} />
                    break;
                case 'Register':
                    activePanel = <RegisterNewUser cancel={this.handleSignOut} success={this.switchVerify}/>
                    break;
                case 'Verify':
                    activePanel = <VerifyCode cancel={this.handleSignOut} />
                    break;
                default:
                    activePanel = <SignOnPanel onSignOnSubmit={this.handleSignOnSubmit} registerNewUser={this.switchRegister} verifyCode={this.switchVerify} cancel={this.handleSignOut} />
                    break;
            }

        }

        return (
            <div>
                <div className='injection-app-header'>
                    <label>{this.state.userName}</label>
                    <label>Injections</label>
                    {signOutPanel}
                </div>
                <div></div>
                { activePanel }
            </div>
        );
      }
}


export default InjectionsApp;