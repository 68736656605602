import React from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import _config from './js/config.json';
import weekday from 'dayjs/plugin/weekday';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import Auth from './Auth.js'
import HistoryForm from './HistoryForm.js'

class HistoryCalendar extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        dateRetrieved: null,
        firstDate: null,
        dateArray: [],
        selectedItem: null};
      this.retrieveData = this.retrieveData.bind(this);
      this.handleResponse = this.handleResponse.bind(this);
      this.handleError = this.handleError.bind(this);
      this.dateCleared = this.dateCleared.bind(this);
      dayjs.extend(weekday);
      dayjs.extend(isSameOrBefore);
    }

    dateCleared()
    {
        this.setState({ dateArray: null, selectedItem: null, dateRetrieved: null});
    }

    handleResponse(response)
    {
        dayjs.extend(weekday);
        dayjs.extend(isSameOrBefore);

        const responseArray = response.data.Response;
        
        var dateArray = []  ;
        const selectedDate = this.props.selectedDate;
        var startDateRange = selectedDate.subtract(14, 'days');
        if (startDateRange.weekday() > 0)
        {
            startDateRange = startDateRange.weekday(-7).add(7, 'days');
        }
        var endDateRange = startDateRange.add(20, 'day');

        var currentDate = dayjs(startDateRange);
        var selectedItem;
        while (currentDate.isSameOrBefore(endDateRange))
        {
            var item = responseArray.find(value => { 
                return currentDate.isSame(value.date, 'day');
            });
            if (item)
            {
                if (selectedDate.isSame(item.date, 'day'))
                {
                    selectedItem = item;
                }
            }
            dateArray.push( { 'date': currentDate.format('YYYY-MM-DD'), 'value': item});

            currentDate=currentDate.add(1, 'day');
        }
        this.setState({ selectedItem: selectedItem,
                        dateArray: dateArray,
                        firstDate: startDateRange,
                        dateRetrieved : selectedDate
                      });

        
    }

    handleError(error)
    {
        if (error.request.status === 401) {
            this.props.signOut();
        }
        else
        {
            alert('Error = ' + error);
            console.log(error);
        }
    }

    retrieveData()
    {
        dayjs.extend(weekday);
        var selectedDate=this.props.selectedDate;
        if (selectedDate.isSame(this.state.dateRetrieved, 'day') && this.state.dateArray )
        {
            return; // Already retrieved
        }        
        var startDate=selectedDate?.subtract(21, 'day');
        var endDate=selectedDate?.weekday(6);
        selectedDate = endDate;

        var getToken = new Auth().getToken();
        getToken.then((token) => {
            const baseURL = _config.api.invokeUrl;
            if (!token)
            {
                alert ('Error, Token not found');
            }
            else
            {
                var headers = { Authorization: token };
                axios.get('/date', {
                    baseURL: baseURL,
                    headers: headers,
                    params: {
                    beginDate: startDate.format('YYYY-MM-DD'),
                    endDate: selectedDate.format('YYYY-MM-DD')
                    }
                })
                .then(this.handleResponse)
                .catch(this.handleError);  
            }

        });
    }

    dayStyle(retrievedDate, dateToStyle)
    {

        if (dayjs(retrievedDate).isSame(dateToStyle, 'day'))
        {
            return "calendar-day-selected";
        }
        else
        {
            return "calendar-day";
        }
    }

    showMonthName(date)
    {
        const currentDate = dayjs(date);
        const useFirstDate = dayjs(this.state.firstDate);
        if ( (currentDate.date() === 1) || currentDate.isSame(useFirstDate, "day")) {
            return dayjs(date).format("MMM");
        }
        else{
            return "";
        }
    }

    getCellColor(date)
    {
        const currentDate = dayjs(date);
        const useFirstDate = dayjs(this.state.firstDate);
        return (currentDate.isSame(useFirstDate, "month")) ? "lightblue" : "lightgreen";
    }

    render() {
        if ( !this.props.selectedDate.isSame(this.state.dateRetrieved, 'day') )
        {
            this.retrieveData();
            return ( <div className='data-loading'><label>Data is Loading.  Please wait!</label></div>);
        } 
        if (this.state.dateArray)
        {
            const retrievedDate = this.state.dateRetrieved;
            const listItems = this.state.dateArray.map((item) =>
                <div onClick={(event) => { this.props.handleDateChange(dayjs(item.date))}} className={this.dayStyle(retrievedDate, dayjs(item.date))} style={{ backgroundColor: this.getCellColor(item.date)}} key={dayjs(item.date).format('YYYY-MM-DD')}>
                    <div  >{this.showMonthName(item.date)} {dayjs(item.date).format('D')}</div>
                    <div><p></p></div>
                    <div >{item.value ? (item.value.site ? item.value.site : '') : '' }</div>
                    <div >{item.value ? (item.value.location ? item.value.location : '') : '' }</div>
                </div>
            );
            const monthStartName = dayjs(retrievedDate).format('MMMM');
            const site = this.state.selectedItem ? this.state.selectedItem.site : null;
            const location = this.state.selectedItem ? this.state.selectedItem.location : null;
            if (retrievedDate)
            {
                return (
                        <div className="main">
                            <div>
                                <label>{monthStartName}</label>
                            </div>
                            <div className="calendar-days-grid">
                                {listItems}
                            </div>
                            <HistoryForm site={site} location={location} date={retrievedDate} key={retrievedDate} dateCleared={this.dateCleared} dateSaved={this.retrieveData}/>
                        </div>
                );
            }
            else{
                return (
                    <div>
                        <label>No Date Selected</label>
                    </div>
                )
            }
        }else
        {
            return <div></div>
        }
      }
}

export default HistoryCalendar;