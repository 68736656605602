import React from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import _config from './js/config.json';
import Auth from './Auth.js'

class HistoryForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        site: props.site,
        location: props.location
    };
      this.siteChanged = this.siteChanged.bind(this);
      this.locationChanged = this.locationChanged.bind(this);
      this.saveForm = this.saveForm.bind(this);
      this.clearForm = this.clearForm.bind(this);
      this.handleSaveResponse = this.handleSaveResponse.bind(this);
      this.handleClearResponse = this.handleClearResponse.bind(this);
    }

    handleClearResponse()
    {
        this.props.dateCleared();
        this.setState({ site: null, location: null});
    }

    clearForm(event)
    {
        event.preventDefault();

        const baseURL = _config.api.invokeUrl;
        var getToken = new Auth().getToken();

        getToken.then((token) => {
            if (!token)
            {
                alert ('Error, Token not fonud');
            }
            else
            {
                var headers = { Authorization: token };
                var url = '/date/' + dayjs(this.props.date).format('YYYY-MM-DD');
                axios.delete(url, {
                    baseURL: baseURL,
                    headers: headers,
                })
                .then(this.handleClearResponse)
                .catch(this.handleError); 
            }
        });

    }

    handleSaveResponse(event)
    {
        this.props.dateCleared();
        //this.forceUpdate();
    }

    handleError(event)
    {
        var response = event.response;
        if (response) {
            console.log(response.data);
            console.log(response.status);
            console.log(response.headers);
          }
    }

    saveForm(event)
    {
        event.preventDefault();
        const selectedSite = this.state.site;
        const selectedLocation = this.state.location;

        if (!selectedSite || !selectedLocation || selectedSite === 'XX' || selectedLocation === 0)
        {
            alert('Site and Location must be selected.');
            return;
        }

        var getToken = new Auth().getToken();

        getToken.then((token) => {
            const baseURL = _config.api.invokeUrl;
            if (!token)
            {
                alert ('Error, Token not fonud');
            }
            else
            {
                const headers = { Authorization: token };
                const url = '/date/' + dayjs(this.props.date).format('YYYY-MM-DD');
                const body = { site: selectedSite, location: selectedLocation};
                axios.put(url, body, { 
                        baseURL: baseURL,
                        headers: headers })
                    .then(this.handleSaveResponse)
                    .catch(this.handleError);
            }
        });

    }
    

    siteChanged(event)
    {
        event.preventDefault();
        const staticSites = this.sites().sites;
        var selectedSite = staticSites.find((site) => site.id === event.target.value);
        this.setState({ 'site' : selectedSite.id, 'location': null});
    }

    locationChanged(event)
    {
        event.preventDefault();
        this.setState({'location': event.target.value});
    }

    sites()
    {
        return {
            sites: [ 
                { id: 'XX', description: '', active: true, locations: []},
                { id: 'LH', description: 'Left Hip', active: true, locations: [ 1, 2, 3, 4, 5, 6, 7, 8] },
                { id: 'RH', description: 'Right Hip ', active: true, locations: [ 1, 2, 3, 4, 5, 6, 7, 8] }, 
                { id: 'LL', description: 'Left Leg', active: true, locations: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]},
                { id: 'RL', description: 'Right Leg', active: true, locations: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]},
                { id: 'ST', description: 'Stomach', active: true, locations: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]},
                { id: 'LA', description: 'Left Arm', active: false, locations:[ 1, 2, 3, 4, 5, 6, 7, 8]},
                { id: 'RA', description: 'Right Arm', active: false, locations: [ 1, 2, 3, 4, 5, 6, 7, 8]}
            ]
        };
    }

    activeSites()
    {
        return this.sites().sites.filter((site) => {
            return site.active;
        });
    }

    isSiteSelected(site, selectedSite)
    {
        if (site === selectedSite) {
            return 'selected';
        }
        else {
            return '';
        }
    }

    render()
    {
        const staticSites = this.sites().sites;
        var activeSites = this.activeSites();

        const selectedSite = this.state.site;
        const selectedLocation = this.state.location;

        var selectedSiteObject = activeSites.find((site) => {return site.id === selectedSite});

        if (selectedSite && !selectedSiteObject) {
            activeSites = staticSites;
            selectedSiteObject = activeSites.find((site) => {return site.id === selectedSite});
        }

        const siteOptions = activeSites.map((site) => 
                <option key={site.id} value={site.id}>{site.description}</option>
        );

        var locations = [];
        if (selectedSiteObject)
        {
            locations = selectedSiteObject.locations;
        }
        const defaultLocationOption = <option key='00' value='0'></option>;
        const locationOptions = locations.map((location) =>
                <option key={location} value={location}>{location}</option>
        );

        const defaultSite = selectedSite ? selectedSite : 'XX';
        const defaultLocation = selectedLocation && selectedSite ? selectedLocation : 0;
        const imageFile = (!selectedSite || (selectedSite === 'XX')) ? require('./images/NONE.jpg') : require('./images/'+ selectedSite + '.jpg'); 
        return (
            <div>
                <form>
                    <div className='history-form-container'>
                        <div className='history-form-left'>
                            <p/>
                            <p><label>Date: {this.props.date.format("MMMM D, YYYY")} </label></p>
                            <p><label className='select-label'>Site:</label><select onChange={this.siteChanged} value={defaultSite}>{siteOptions}</select></p>
                            <p><label className='select-label'>Location:</label><select onChange={this.locationChanged} value={defaultLocation}>{defaultLocationOption}{locationOptions}</select></p>
                        </div>
                        <div className='history-form-right'>
                            <img src={imageFile} alt={'Image of ' + selectedSite}></img>
                        </div>
                    </div>
                    <div className='history-form-button-bar'>
                        <input class='save' type="submit" value="Save" onClick={this.saveForm}></input>
                        <input class='clear' type="submit" value="Clear" onClick={this.clearForm}></input>
                    </div>
                </form>
            </div>);
    }
}

export default HistoryForm;